import { create } from "@storybook/theming";
import BASE_UI_THEME from "../src/core/theme/muiTheme";

export default create({
  base: "light",
  brandTitle: "Vysion Design system",
  brandUrl: "https://vysion.cloud",
  brandImage:
    "https://uploads-ssl.webflow.com/602032d896ebfb716120eb32/602102723b879074fdb8a5b8_ALVYSION-Logo%20Blue.png",
  ...BASE_UI_THEME,
});
